
<template>
  <div>
    <el-dialog
      title="新增办事指南"
      :visible.sync="visible"
      width="50%"
      :z-index="99"
      :close-on-click-modal="true"
      :show-close="true"
    >
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          <a-select
            default-value="事件分类"
            style="width: 100%"
            @change="FileChange"
          >
            <a-select-option value="0"> 事件分类 </a-select-option>
            <a-select-option value="1"> 视频 </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="18">
          <a-select default-value="资讯分类001" style="width: 100%">
            <a-select-option value="0"> 资讯分类001 </a-select-option>
            <a-select-option value="1"> 资讯分类002 </a-select-option>
            <a-select-option value="2"> 资讯分类003 </a-select-option>
            <a-select-option value="3"> 资讯分类004 </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <!-- 图片上传 -->
        <a-col :span="24">
          <a-upload-dragger
            name="file"
            :multiple="true"
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="file-image" />
            </p>
            <p class="ant-upload-text">
              将封面图拖到此区域，或<span style="color: #69b9ff">点击上传</span>
            </p>
            <p class="ant-upload-hint">
              封面必须小于5M,仅限:jpg/png/jpeg 尺寸为: 1006*240 px
            </p>
          </a-upload-dragger></a-col
        >
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="24">
          <a-input placeholder="请输入事项名称..." />
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="24">
          <quillEditor @onChange="quillHtml" v-if="fileType"></quillEditor>
          <upload-video :fileTypes="['video/mp4']" v-else></upload-video>
        </a-col>
      </a-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="mapCancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
 <script>
import quillEditor from "../../../../Acomponents/quillEditor/wangeditor.vue";
import UploadVideo from "../../../../Acomponents/uploadVideo";
export default {
  components: { quillEditor, UploadVideo },
  data() {
    return {
      visible: false,
      quillHtmls: "",
      fileType: true,
    };
  },

  methods: {
    openDialog() {
      this.visible = true;
    },
    mapCancel() {
      this.visible = false;
    },
    // 图片上传
    handleChange(info) {
      const status = info.file.status;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    // 富文本编辑器获取内容
    quillHtml(e) {
      this.quillHtmls = e;
    },
    FileChange(e) {
      e === "0" ? (this.fileType = true) : (this.fileType = false);
    },
  },

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

/deep/.ant-input {
  height: 0.2rem;
  border: none;
  color: #000;
  border-bottom: 1px solid#c9ced1;
  border-radius: 0;
}
/deep/.ant-input::placeholder {
  color: #000;
  font-size: 0.07rem;
  font-weight: 700;
}
</style>