<template>
  <div class="upload-box">
    <div class="avatar-uploader-box">
      <!-- 图片预览 -->
      <div
        :key="index"
        class="video-preview"
        v-for="(item, index) in videoList"
      >
        <video
          :src="item.url"
          @mouseover.stop="item.isShowPopup = true"
          class="avatar"
        >
          您的浏览器不支持视频播放
        </video>
        <!-- 显示查看和删除的按钮弹窗 -->
        <div
          @mouseleave="item.isShowPopup = false"
          class="avatar-uploader-popup"
        >
          <i @click="previewVideo(item)" class="el-icon-zoom-in"></i>
          <i @click="deleteVideo(index)" class="el-icon-delete"></i>
        </div>
      </div>

      <!-- 方框样式 -->
      <el-upload
        :action="actionUrl"
        :auto-upload="true"
        :on-success="fileSuccess"
        :on-error="fileError"
        :before-upload="beforeAvatarUpload"
        :show-file-list="false"
        class="avatar-uploader"
        ref="avatarUploader"
        v-show="uploadShow"
        :headers="headers"
      >
        <span
          element-loading-background="rgba(0, 0, 0, 0.8)"
          element-loading-spinner="el-icon-loading"
          element-loading-text="上传中"
          style="display: block"
          v-loading="loading"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </span>
      </el-upload>
      <div style="margin-bottom: 10px; margin-top: 10px">上传视频</div>
      <div>
        支持 {{ this.fileTypes.join(", ") }} 格式,单个文件最大
        {{ this.sizeLimit }}M
      </div>
    </div>

    <!-- 查看大图 -->
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      center
      title="视频查看"
      :before-close="handleClose"
    >
      <video :src="videoSrc" ref="video" controls alt width="100%">
        您的浏览器不支持视频播放
      </video>
    </el-dialog>
  </div>
</template>
   
  <script>
import { axiosInstance } from "@/common/request";
import cookieUtil from "@/common/cookie-util";
export default {
  name: "uploadVideo",
  components: {},
  props: {
    videos: {
      type: Array,
      default: function () {
        return [];
      },
    },
    numLimit: {
      // 最大允许上传个数
      type: Number,
      default: 1,
    },
    fileTypes: {
      // 允许上传文件类型
      type: Array,
      default: function () {
        return [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ];
      },
    },
    sizeLimit: {
      // 最大单文件大小
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      videoList: [],
      videoSrc: "",
      actionUrl: `${axiosInstance.defaults.baseURL}/file/wangeditorUpload`,
      headers: {
        authorization: cookieUtil.getToken(),
      },
    };
  },
  computed: {
    uploadShow() {
      return this.videoList.length < this.numLimit;
    },
  },
  watch: {},
  created() {},
  methods: {
    // 上传之前
    beforeAvatarUpload(file) {
      const isCorrectType = this.fileTypes.includes(file.type);
      const isWithinSizeLimit = file.size / 1024 / 1024 <= this.sizeLimit;
      const isWithinNumLimit = this.videoList.length < this.numLimit;
      if (!isCorrectType) {
        this.$message.error("请上传正确的视频格式!");
        return false; // 阻止上传
      } else if (!isWithinSizeLimit) {
        this.$message.error(`视频大小不能超过${this.sizeLimit}M!`);
        return false; // 阻止上传
      } else if (!isWithinNumLimit) {
        this.$message.error(`最多上传${this.numLimit}个视频`);
        return false; // 阻止上传
      }

      // 如果所有条件都满足，则不返回任何值（或返回 true），允许文件上传
    },
    // 上传成功
    fileSuccess(file, fileList) {
      this.videoList.push({
        videoFile: file.data,
        url: file.data.url,
        isShowPopup: false,
      });
      this.$message.success("上传成功");
    },
    // 上传失败
    fileError() {
      this.$message.error("上传失败");
    },

    // 预览视频
    previewVideo(data) {
      this.videoSrc = data.url;
      this.dialogVisible = true;
    },
    // 删除视频
    // 目前只删除本地,远程数据删除需联调
    deleteVideo(index) {
      this.videoList.splice(index, 1);
    },
    // 关闭预览
    handleClose() {
      const video = document.getElementsByTagName("video")[1];
      if (!video.paused) {
        video.currentTime = 0;
        video.pause();
      }
      this.dialogVisible = false;
    },
    retVideoList() {
      return this.videoList;
    },
  },
};
</script>
   
  <style lang="scss">
$width: 90px;
$height: 90px;

.upload-box {
  .avatar-uploader-box {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    min-width: 350px;
    .avatar-uploader {
      .el-upload {
        width: $width;
        height: $height;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
          border-color: #409eff;
        }
        .el-loading-spinner {
          width: $width;
          height: $height;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 0;
          top: 0;
          .el-loading-text {
            margin: 0;
          }
        }
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: $width;
        height: $height;
        line-height: $height;
        text-align: center;
      }
    }
    .video-preview {
      position: relative;
      padding-right: 20px;
      .avatar {
        width: $width;
        height: $height;
        display: block;
        border-radius: 6px;
      }
      .avatar-uploader-popup {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: $width;
        height: $height;
        background: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        border-radius: 6px;
        i {
          width: 30%;
          text-align: center;
          padding: 0 5%;
          font-size: 24px;
        }
      }
    }
    .upload-tip {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 12px;
      color: #606266;
      max-width: 350px;
      line-height: 20px;
    }
  }
}
</style>